.game-play {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-size: cover;
  background-position: center;

}

.scoreboard {
  color: white;
  font-weight: 900;
  margin-left: 5px;
  position: fixed;
  right: 10px;
  top: 15px;
  padding: 10px;
  background-color: #722504;
  border-radius: 10px;
  border: 1px solid white;
  font-family: "Pangolin", cursive;
  font-weight: 700;
}

.start-button {
  margin-left: 10px;
  font-family: "Pangolin", cursive;
  font-weight: 700;
}

/* Modal */
.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media (max-width: 500px) {

  .start-button {
    font-size: 15px;
    padding: 10px;
  }

  .scoreboard {
    font-size: 12px;
    padding: 5px;
  }

}