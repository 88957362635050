.container-page {
  width: 100%;
  height: 100vh;
  font-family: "Pangolin", cursive;
  font-weight: 700;
  font-style: normal;
  color: black;
}

.containe-home {
  width: 100%;
  height: 100%;
  background-color: rgba(98, 110, 106)
}

.home-galery {
  position: relative;
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
}

.home-galery p {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  font-size: 2.5em;
  font-weight: bold;
  font-family: "Pangolin", cursive;
  text-shadow: 1px 1px black;
}

.home-banner {
  width: 100%;
  height: auto;
}

.banner-m,
.roadmap-m,
.gallery-m {
  display: none;
}

.home-banner img {
  object-fit: cover;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(98, 110, 106, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.loading-screen img {
  max-width: 80vw;
}

/* button switch */

.credit-card {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-switch {
  width: 20vw;
  color: white !important;
  font-size: 20px;
  font-weight: 600;
  text-shadow: 1px 1px 0px #7cacde;
  box-shadow: 1px 1px 1px #bee2f9;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid white;
  background: linear-gradient(to top, #9a7f5f, #d38627);
}

@media (max-width:900px) {
  .container-page {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    font-family: "Pangolin", cursive;
    font-weight: 700;
    font-style: normal;
    color: black;
    background-color: #6d5a4e;
  }

  .home-galery p {
    font-size: 25px;
  }

}