.coin {
    position: absolute;
    top: -10px;
    /* Start from top of the screen */
    width: 3vw;
    /* Adjust size of the coin */
    height: 3vw;
    /* Adjust size of the coin */
    background-image: url("/public/assets/chicken/coin.gif");
    /* Replace with path to your coin image */
    background-size: cover;
    animation: fall linear infinite;
    opacity: 90%;
}


@keyframes fall {
    0% {
        transform: translateY(-10px);
        /* Start position */
    }

    100% {
        transform: translateY(100vh);
        /* End position (bottom of the screen) */
    }
}

@media (max-width: 900px) {
    .coin {
        width: 5vw;
        height: 5vw;
    }
}