.white-paper {
    background: url('/public/assets/images/white_paper_bg.png') top center no-repeat,
        url('/public/assets/images/roadmap.webp') top center no-repeat;
    background-size: contain, cover;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    text-align: center;
    padding-top: 20px;
    box-sizing: border-box;
    font-family: "Pangolin", cursive;
}

.content {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    margin-top: 10vh;
}

.content p {
    font-size: x-large;
    color: white;
    text-shadow: 1px 1px white;
    text-align: left;
}



.egg-container {
    position: absolute;
    bottom: 15vh;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    gap: 10px;
}

.egg {
    position: relative;
}

.egg img {
    width: 13vw;
    transition: transform 0.3s, box-shadow 0.3s;
}

.egg img:hover {
    transform: scale(1.2);
}

.egg-description {
    display: none;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-30%);
    background-color: white;
    color: black;
    padding: 10px;
    border-radius: 5px;
    width: 500px;
    text-align: left;
    white-space: pre-wrap;
    z-index: 2;
}

.egg:hover .egg-description {
    display: block;
    font-size: 20px;
    z-index: 2;
}

.straw {
    position: absolute;
    bottom: -5vh;
    left: 50%;
    transform: translateX(-50%);
    width: 65%;
    height: auto;
    z-index: 0;
}

/* Button */
.input-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    margin: 5vh 0;
    min-width: 40vw;
}

.input-password div {
    margin-bottom: 5px;
}

.input-password label {
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    font-size: x-large;
    color: black;
    text-shadow: 1px 1px white;
}

.input-password input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    /* Ensure it takes full width of its container */
    max-width: 300px;
    /* Limit width for better appearance */
}

.button-send {
    display: inline-block;
    padding: 6px 20px;
    font-size: 20px;
    color: black;
    text-shadow: 1px 1px black;
    background-color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
}

.button-send:hover {
    background-color: #7e7e7e;
}

@media (max-width:1300px) {
    .white-paper {
        background: url('/public/assets/images/roadmap.webp') top center no-repeat;
        background-size: cover;
    }

    .content p {
        font-size: 20px;
        padding: 20px 50px;
    }

    .input-password {
        gap: 10px;
        margin: 0;
        min-width: 40vw;
    }

    .straw {
        bottom: 0;
    }
}

@media (max-width:900px) {
    .content {
        padding: 20px;
        display: flex;
        margin-top: 0;
        flex-direction: column;
    }

    .egg img {
        width: 15vw;
    }

    .straw {
        bottom: 0;
        width: 80%;
        z-index: 0;
    }

    .content p {
        font-size: 18px;
        padding: 10px;

    }

    .egg-description {
        width: 200px;
    }

    .egg:hover .egg-description {
        font-size: 16px;
    }

}

@media (max-width:650px) {
    .white-paper {
        background: url('/public/assets/images/roadmap.webp') top center no-repeat;
        background-size: cover;
        height: 100%;
    }

    .egg img {
        width: 18vw;
    }

    .straw {
        bottom: -150px;
        width: 100%;
        z-index: 0;
    }

    .egg-container {
        position: absolute;
        bottom: 50px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .content {
        margin-top: 5vh 0 5vh;
    }

    .content p {
        font-size: 16px;
        padding: 10px 30px;
        text-shadow: none;
        font-weight: 700;
    }

    .input-password label {
        font-size: large;
        text-shadow: none;
        font-weight: 700;
        color: white;
    }

    .egg-description {
        width: 120px;
        font-size: 12px;
    }

    .egg:hover .egg-description {
        font-size: 12px;
    }

}