@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {

    .home-game {
        width: 100%;
        height: 100%;
        min-height: 100vh;
        margin-top: 0;
        background-position: center;
        font-family: "Pangolin", cursive;
        font-weight: 700;
        font-style: normal;
    }

    .logo {
        position: fixed;
        top: 5px;
        left: 5px;
        font-size: 30px;
        font-family: "Pangolin", cursive;
        font-weight: 700;
        color: #722504;
    }

    .connect-wl {
        position: fixed;
        max-width: 35vw;
        top: 10px;
        right: 50px;
        z-index: 50;
    }

    .container-game {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: flex-start;
        padding-top: 7vh;
    }

    .content-game {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .content-game p {
        width: 30vw;
        font-size: 25px;
        color: white;
        text-shadow: 1px 1px black;
        margin-top: 2vh;
    }

    .content-game img {
        width: 20vw;
        margin-bottom: 20px;
    }

    .discordInput {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }

    .discordInput input {
        font-size: 20px;
        padding: 8px;
        border-radius: 20px;
        border: 2px solid black;
    }

    .tableRank table {
        border-radius: 20px;
    }

    /* leader-board */

    .leader-board {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .table-rank {
        max-height: 80vh;
        max-width: 40vw;
        overflow-y: auto;
        margin-top: 20px;
        position: relative;
        border: 2px solid gray;
        background-color: white;
        box-shadow: white;
        border-radius: 20px;
    }

    .top-100 {
        font-size: 30px;
        font-family: "Pangolin", cursive;
        font-weight: 700;
        color: white;
    }

    .table-rank th {
        font-size: 20px;
        border-bottom: 2px solid #6e6e6e;
        padding-left: 10px;
        padding-right: 10px;
    }

    .wallet-lb {
        border: none;
        min-width: 15vw;
        display: flex;
        justify-content: center;
    }

    .table-rank td {
        border-bottom: 1px solid #6e6e6e;
        text-align: center;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: 500;
        font-size: 20px;
        max-width: 20vw;
        overflow-y: auto;
    }

    .highlighted-row {
        width: 100%;
        height: 40px;
        background-color: #a78bfa;
        position: sticky;
        top: 0;
        z-index: 1;
        font-weight: bold;
    }

    .social-link {
        position: fixed;
        left: 50px;
        bottom: 50px;
    }

    .social-link img:hover {
        transform: translate(-10%, -10%) rotate(-10deg);
    }


    .social-link img {
        width: 70px;
        gap: 10px;
    }


    .testbutton {
        color: white !important;
        font-size: 20px;
        font-weight: 600;
        text-shadow: 1px 1px 0px #7cacde;
        box-shadow: 1px 1px 1px #bee2f9;
        padding: 10px 25px;
        border-radius: 10px;
        border: 2px solid white;
        background: linear-gradient(to top, #9a7f5f, #d38627);
    }

    .testbutton:hover,
    .button-switch:hover {
        transform: scale(1.1);
    }



    @media (max-width:1500px) {
        .container-game {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-top: 15vh;
            margin: 2px 5vw 2px 5vw;
        }

        .table-rank {
            max-width: 50vw;
        }

        .social-link {
            position: fixed;
            left: 20px;
            bottom: 20px;
        }

        .social-link img {
            width: 60px;
            gap: 10px;
        }

        .table-rank {
            max-height: 70vh;
        }

    }

    @media (max-width:1000px) {
        .home-game {}

        .container-game {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-top: 5vh;
            margin: 0;
            padding-bottom: 5vh;
        }

        .table-rank {
            max-width: 80vw;
            max-height: 80vh;
        }

        .content-game {
            display: flex;
            align-items: center;
            gap: 5vw;
        }

        .content-game img {
            width: 30vw;
            margin-bottom: 20px;
        }

        .connect-wl {
            top: 5vh;
            right: 10px;
        }

        .social-link {
            left: 10px;
            bottom: 10px;
            z-index: 10;
        }

        .social-link img {
            width: 60px;
            gap: 10px;
        }

        .content-game p {
            width: 80vw;
            margin-top: 0;
            margin-bottom: 20px;
        }

    }

    @media (max-width:650px) {
        .table-rank th {
            font-size: 15px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .table-rank td {
            padding-left: 5px;
            padding-right: 5px;
            font-size: 15px;
            max-width: 20vw;
        }

        .table-rank {
            max-width: 98vw;
        }

        .top-100 {
            font-size: 25px;
        }

        .social-link img {
            width: 50px;
        }

        .discordInput input {
            font-size: 16px;
            padding: 5px;
            border-radius: 10px;
            max-width: 35vw;
        }

        .play-game {
            font-size: 15px;
            padding: 10px;
        }

        .connect-wl {
            max-width: 40vw;
        }

        .content-game p {
            font-size: 18px;
            width: 90vw;
        }

    }

    @media (max-width:480px) {
        .table-rank th {
            font-size: 14px;
            padding-left: 5px;
            padding-right: 5px;
        }

        .table-rank td {
            padding-left: 0;
            padding-right: 0;
            font-size: 12px;
            max-width: 20vw;
        }

        .connect-wl button {
            padding-left: 10px;
            padding-right: 10px;
        }

        .connect-wl button span {
            font-size: 13px;
        }

        .logo {
            font-size: 25px;
        }

    }

}