.social-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    color: white;
    background: url(/public/assets/images/socials.webp) top center no-repeat;
    background-size: cover;
}

.social-page p {
    font-size: 25px;
}

.social-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35vh;
}

.social-links {
    margin-top: 2px;
    display: flex;
    gap: 1vw;
}

.social-links img:hover {
    transform: translate(-10%, -10%) rotate(-10deg);
}

.social-links img {
    width: 70px;
}

.social-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 80vw;
    margin: 2px 10vw 5px 10vw;
}

.social-content img {
    width: auto;
    height: 45vh;
}

.text-animation {
    font-size: 50px;
    text-shadow: 1px 1px red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    animation: blink 1s infinite;
}

@keyframes blink {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }
}

.social-text {
    display: none;
}

@media (max-width:1500px) {
    .text-animation {
        font-size: 30px;
    }
}

@media (max-width:900px) {

    .social-page {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        color: white;
        background: url(/public/assets/images/roadmap.webp) top center no-repeat;
        background-size: cover;
    }

    .social-header {
        margin-top: 2vh;
        font-family: "Pangolin", cursive;
    }

    .social-content img {
        height: 40vh;
    }

    .social-content {
        display: flex;
        flex-direction: column;
        gap: 50px;
        margin-top: 5vh;
        margin-bottom: 10vh;
        font-family: "Pangolin", cursive;
    }

    .social-links {
        gap: 30px
    }

    .social-links img {
        width: 60px !important;
    }

    .social-header p {
        font-size: 17px;
    }

    .text-animation {
        transform: none;
    }

    .social-text {
        display: block;
        margin-top: 5vh;
    }

}

@media (max-width:500px) {
    .social-header {
        margin-top: 5vh;
        font-family: "Pangolin", cursive;
    }

    .social-content {
        display: flex;
        flex-direction: column;
        font-family: "Pangolin", cursive;
    }

    .social-links img {
        width: 60px !important;
    }

    .social-links {
        gap: 10px
    }


    .text-animation {
        font-size: 40px;
    }

}