.custom-loader {
    --R: 30px;
    --g1: #766df4 96%, #0000;
    --g2: #e4e4ed 96%, #0000;
    width: calc(2 * var(--R));
    height: calc(2 * var(--R));
    border-radius: 50%;
    display: grid;
    -webkit-mask: linear-gradient(#000 0 0);
    animation: s10 2s infinite linear;
}

.custom-loader::before,
.custom-loader::after {
    content: "";
    grid-area: 1/1;
    width: 50%;
    background: radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866 * var(--R) - var(--R)) calc(var(--R) - 0.5 * var(--R) - var(--R)),
        radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866 * var(--R) - var(--R)) calc(var(--R) - 0.5 * var(--R) - var(--R)),
        radial-gradient(farthest-side, var(--g2)) calc(var(--R) + 0.5 * var(--R) - var(--R)) calc(var(--R) - 0.866 * var(--R) - var(--R)),
        radial-gradient(farthest-side, var(--g1)) 0 calc(-1 * var(--R)),
        radial-gradient(farthest-side, var(--g2)) calc(var(--R) - 0.5 * var(--R) - var(--R)) calc(var(--R) - 0.866 * var(--R) - var(--R)),
        radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866 * var(--R) - var(--R)) calc(var(--R) - 0.5 * var(--R) - var(--R)),
        radial-gradient(farthest-side, var(--g2)) calc(-1 * var(--R)) 0,
        radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866 * var(--R) - var(--R)) calc(var(--R) + 0.5 * var(--R) - var(--R));
    background-size: calc(2 * var(--R)) calc(2 * var(--R));
    background-repeat: no-repeat;
}

.custom-loader::after {
    transform: rotate(180deg);
    transform-origin: right;
}

@keyframes s10 {
    100% {
        transform: rotate(-1turn);
    }
}

.sign-in {
    width: 70vw;
    height: 50vh;
    background-color: white;
    opacity: 1;
    border-radius: 50px;
    border: 2px solid #6e6e6e;
    border-bottom: 5px solid #6e6e6e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.sign-in h1 {
    font-size: 30px;
    font-weight: 700;
    font-family: Changa;
}

.sign-in i {
    margin: 2px 10vw 2px 10vw;
}

@media (max-width:1200px) {
    .sign-in {
        z-index: 10;
    }

    .sign-in h1 {
        font-size: 28px;
    }

    .sign-in i {
        margin: 2px 0 2px 0;
        font-size: 15px;
    }
}

@media (max-width:900px) {

    .sign-in h1 {
        font-size: 25px;
    }

    .sign-in i {
        font-size: 15px;
        margin: 10px 5vw 10px 5vw;
    }

    .sign-in {
        width: 80vw;
        border-radius: 30px;
    }

    .button-color {
        font-size: 16px;
        margin-top: 20px;
        padding: 12px 24px;
        border-radius: 30px;
    }

}

@media (max-width:500px) {
    .button-color button {
        font-size: 15px;

    }

    .button-color {
        padding: 10px 22px;
        border-radius: 20px;
    }
}