.bg-roadmap {
    width: 100%;
    height: auto;
    background: url(/public/assets/images/roadmap.webp) center center no-repeat;
    background-size: cover;
    padding: 20px;
    box-sizing: border-box;
    font-family: "Pangolin", cursive;
    font-weight: 700;
}

.roadmap-item {
    display: flex;
    align-items: center;
    min-height: 40vh;
}

.roadmap-item img {
    height: 29vh;
    width: auto;
}

.roadmap-left {
    justify-content: flex-start;
}

.roadmap-right {
    justify-content: flex-end;
}

.roadmap-content {
    box-sizing: border-box;
    color: black;
}

.bg-content {
    background-image: url(/public/assets/images/bg_paper.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: 100px 50px;
}

.roadmap-content b {
    display: block;
    font-size: 28px;
    color: white;
    font-weight: 700;
    text-align: center;
    padding-top: 70px;
    margin-bottom: -50px;
}

.roadmap-content p {
    margin: 10px 0;
    font-size: 18px;
}

/* Media Queries for responsive design */
@media (max-width: 1200px) {
    .roadmap-item {
        flex-direction: column;
        font-family: "Pangolin", cursive;
        font-weight: 500;
    }

    .roadmap-left,
    .roadmap-right {
        justify-content: center;
    }

    .roadmap-content {
        max-width: 100%;
        padding: 30px;
    }

    .roadmap-item img {
        height: 30vh;
        margin-bottom: 20px;
    }

    .roadmap-item img {
        height: 15vh;
        order: -1;
        margin-bottom: 20px;
    }
}

@media (max-width: 900px) {
    .bg-roadmap {
        width: 100%;
        padding: 20px;
        box-sizing: border-box;
        background: url(/public/assets/images/roadmap_m.webp) center center no-repeat;
        background-size: cover;
    }

    .bg-content {
        background-image: url(/public/assets/images/paper_m.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .roadmap-content b {
        font-size: 18px;
    }

    .roadmap-content p {
        font-size: 16px;
    }

    .roadmap-content {
        padding: 20px;
    }

    .roadmap-content {
        background-size: cover;
    }

    .roadmap-item img {
        height: 20vh;
    }
}

@media (max-width: 480px) {
    .roadmap-item {
        flex-direction: column;
        text-align: center;
    }

    .roadmap-item img {
        height: 15vh;
        order: -1;
        margin-bottom: 20px;
    }

    .roadmap-content b {
        font-size: 16px;
    }

    .roadmap-content p {
        font-size: 14px;
    }

    .roadmap-content {
        padding: 10px;
    }
}