.bg-about {
    background: url(/public/assets/images/roadmap.webp) center center no-repeat;
    background-size: cover;
}

.about-page {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    gap: 50px;
    font-family: "Pangolin", cursive;
    font-weight: 700;
    font-style: normal;
}


.container-page img {
    width: 35vw;
    max-height: 80vh;
}

.content-about {
    margin-left: 2vw;
    margin-right: 2vw;
}

.about-page b {
    font-size: 3.5vw;
    color: #ffcc00;
    text-shadow: 1px 1px black;
    margin-bottom: 2vh;
}

.about-page p {
    font-size: 23px;
    color: rgb(255, 255, 255);
    text-shadow: 1px 1px rgb(65, 63, 63);
    line-height: 1;
    margin-bottom: 1.2rem;
}

@media (max-width:900px) {
    .about-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: flex-start;
        background: url(/public/assets/images/roadmap_m.webp) center center no-repeat;
        background-size: cover;
    }


    .about-page b {
        font-size: 40px;
    }

    .about-page p {
        font-size: 20px;
    }

    .content-about {
        margin: 50px 10vw 10vh 10vw;
    }

    .container-page img {
        width: 50vw;
        margin-top: 10vh;
        margin-bottom: 0;
    }
}

@media (max-width:500px) {
    .about-page b {
        font-size: 30px;
    }

    .about-page p {
        font-size: 15px;
    }

    .content-about {
        margin: 10px 10vw 10px 10vw;
    }
}