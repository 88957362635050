.story-page {
    width: 100%;
    height: 100vh;

}

.story {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: url('/public/assets/images/roadmap.webp') top center repeat-y;
    background-size: contain;
}

.story img,
.storym img {
    min-width: 60vw;
    width: auto;
    height: 100vh;
}

@media (max-width:900px) {

    .story img {
        width: auto;
        height: auto;
        object-fit: cover;
    }
}