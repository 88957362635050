/* modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    overflow-y: auto;
}


.modal-body {
    text-align: left;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    margin: 11vh 15vw 10vh 15vw;
    color: black;
    font-family: "Pangolin", cursive;
    font-weight: 700;
}

.modal-body p {
    font-size: 25px;
}

.modal-body h1 {
    font-size: 25px;
    margin-top: 5vh;
}

@media (max-width:900px) {
    .modal-body p {
        font-size: 20px;
    }

    .modal-body h1 {
        font-size: 20px;
        margin-top: 3vh;
    }
}

@media (max-width:650px) {
    .modal-body img {
        width: 100vw;
        height: auto;
        object-fit: auto;
    }

    .modal-body p {
        font-size: 18px;
        margin-top: 10vh;
    }

    .modal-body {
        margin: 0;
    }

    .modal-content {
        margin-bottom: 10vh;
    }

}