.menu-container {
    position: sticky;
    top: 0;
    left: 0;
    height: 5vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(149, 116, 97, 0.5);
    z-index: 10;
    padding: 0 100px;
    box-sizing: border-box;
}

.menu-tabs .active {
    font-weight: 900;
    color: rgb(207, 3, 3);
}

.text-logo img {
    max-height: 4vh;
}

.menu-container b {
    font-size: 30px;
    font-family: "Pangolin", cursive;
    font-weight: 700;
    font-style: normal;
    color: white;
}

a {
    text-decoration: none;
}

.menu-tabs a {
    font-family: "Pangolin", cursive;
    font-weight: 700;
    font-style: normal;
    color: white;
    font-size: 25px;
    margin-left: 2vw;
    cursor: pointer;
}

/* Hamburger menu icon */
.menu-hamburger {
    display: none;
    font-size: 30px;
    cursor: pointer;
}

/* button-mint */
.link-mint {
    display: inline-block;
    padding: 3px 8px;
    background: linear-gradient(to top, #9a7f5f, #d38627);
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 13px;
    border-radius: 8px;
    box-shadow: 2px 2px white;
    transition: transform 0.2s ease-in-out;
    animation: shake 0.8s infinite;
}

@keyframes shake {
    0% {
        transform: translate(0, 0) rotate(0);
    }

    20% {
        transform: translate(-5px, 0) rotate(-5deg);
    }

    40% {
        transform: translate(5px, 0) rotate(5deg);
    }

    60% {
        transform: translate(-5px, 0) rotate(-5deg);
    }

    80% {
        transform: translate(5px, 0) rotate(5deg);
    }

    100% {
        transform: translate(0, 0) rotate(0);
    }
}

@media (max-width: 900px) {
    .menu-container {
        padding: 0 0;
    }

    .link-mint {
        animation: none;
    }

    .text-logo {
        display: none;
    }

    .menu-container {
        justify-content: left;
    }

    .menu-tabs a {
        margin-left: 5vw;
    }

    .menu-hamburger {
        display: block;
    }

    .menu-tabs {
        display: none;
        flex-direction: column;
        position: absolute;
        top: 5vh;
        left: 0;
        width: 100%;
        background-color: rgba(149, 116, 97, 0.9);
    }

    .menu-tabs.open {
        display: flex;
    }

    .menu-tabs a {
        margin: 10px 0;
        font-size: 20px;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .menu-tabs a {
        font-size: 17px;
    }
}