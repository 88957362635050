.ca-chicken {
    width: 100%;
    height: 100%;
    background: url(/public/assets/images/roadmap.webp) top center no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: "Pangolin", cursive;
    font-weight: 700;
    font-style: normal;
}

.ca-chicken h1 {
    font-size: 60px;
    color: #fcc900;
    margin-bottom: 1vh;
    text-shadow: 1px 1px white;
}


.text-chicken {
    font-size: 30px;
    margin: 2px 5vw 10px 5vw;
    color: white;
    text-shadow: 1px 1px black;
}

.ca-content {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 5vw;
}

.ca-text {
    font-size: 30px;
    color: white;
}

.ca-content img {
    height: 60vh;
}

.frame-content {
    padding-inline: 70px;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fcc900;
    margin-bottom: 50px;
    box-shadow: 2px 2px white;
}

.frame-content b {
    font-size: 25px;
}

.banner-chicken {
    background: url('/public/assets/story/bg_story.webp') top center no-repeat;
    background-size: cover;
}


.chart-chicken,
.banner-chicken {
    width: 100%;
}

.trade-chicken {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    background: url('/public/assets/images/roadmap.webp') top center no-repeat;
    background-size: cover;
}

.chart-chicken img,
.banner-chicken img {
    object-fit: cover;
}

.trade-chicken img {
    height: 100vh;
    object-fit: contain;
}

@keyframes falling {
    0% {
        transform: translateY(-100px);
        opacity: 0;
    }

    100% {
        transform: translateY(100vh);
        opacity: 1;
    }
}

@media (max-width: 900px) {

    .ca-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5vw;
    }

    .ca-content img {
        height: 30vh;
        width: auto;
    }

    .ca-chicken h1 {
        font-size: 50px;
    }

    .text-chicken {
        font-size: 22px;
    }
}

@media (max-width: 650px) {
    .frame-content {
        padding-inline: 50px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ca-content p {
        font-size: 22px;
    }

    .ca-text p {
        font-size: 20px;
        color: white;
    }
}