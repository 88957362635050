.running-text-container {
    width: 100%;
    overflow: hidden;
    height: 7vh;
    background-color: azure;
    border-top: 5px solid black;
    border-bottom: 5px solid black;
}

.running-text {
    font-size: 2vw;
    font-weight: 700;
    font-family: "Pangolin", cursive;
    white-space: nowrap;
    animation: scrolling 13s linear infinite;
    text-shadow: 1px 1px #fcc900;
}

@keyframes scrolling {
    0% {
        transform: translateX(-200%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@media (max-width:1100px) {
    .running-text-container {
        height: 5vh;
    }

}

@media (max-width:900px) {
    .running-text-container {
        height: 4vh;
    }

}

@media (max-width:650px) {
    .running-text {
        font-size: 3vw;
    }

}